* {
  font-family: "Poppins", sans-serif;
}

section {
  background: #000;
  color: #e40a0a;
  padding: 20px;
  font-weight: bold;
  letter-spacing: 0.5px;
}

.textel-right {
  text-align: justify;
}

.social-links,
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2%;
}

.social-btn {
  cursor: pointer;
  height: 40px;
  width: 40px;
  font-family: "Titillium Web", sans-serif;
  color: #333;
  border-radius: 10px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  background: white;
  margin: 5px;
  transition: 0.3s;
  justify-content: center;
}

.social-btn svg {
  height: 24px;
  width: 24px;
}

.social-btn span {
  width: 0px;
  overflow: hidden;
  transition: 0.3s;
  text-align: center;
  margin-left: 2px;
}

.social-btn:hover {
  width: 200px;
  border-radius: 5px;
}

.social-btn:hover span {
  padding: 5px;
  width: 150px;
}

#whatsapp svg {
  fill: #06a509;
}

#email svg {
  fill: #a8130e;
}

.navbar {
  padding: 10px 0;
  opacity: 1;
  visibility: hidden;
  transform-origin: top;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.navbar.color-active {
  background-color: #191919;
  padding: 10px 0;
  opacity: 1;
  visibility: visible;
}

.services {
  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url("../assets/hero1.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  display: flex;
  align-items: center;
  justify-content: center;
}

.my-card {
  transition: all 0.3s ease-in-out;
  transform: scale(1);
}

.my-card:hover {
  transform: scale(1.1);
  box-shadow: 0 0 50px rgba(255, 89, 89, 0.5);
}

.serpis {
  background-color: #1f1414;
  border-radius: 0px 0px 50px 50px;
}

.containerel {
  margin-top: 5rem;
}

.containerel h1 {
  color: #e0e0e0;
}

.containerel p {
  color: #e0e0e0 !important;
}

.cardel {
  width: 250px;
  height: 304px;
  border-radius: 1em;
  padding: 1.9rem;
  background: #f5f5f5;
  position: relative;
  display: flex;
  align-items: flex-end;
  transition: 0.4s ease-out;
  box-shadow: 0px 7px 20px rgba(43, 8, 37, 0.2);
  border-radius: 20%;
}

.cardel:before {
  content: "";
  border-radius: 20%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(rgba(26, 34, 25, 0.771), rgba(20, 23, 20, 0.755)), url("../assets/Gallery/elec.jpg");
  background-repeat: no-repeat;
  opacity: 0;
  background-size: cover;
  align-items: center;
  z-index: 2;
  transition: 0.5s;
}

.cardelwa {
  width: 250px;
  height: 304px;
  border-radius: 1em;
  padding: 1.9rem;
  background: #f5f5f5;
  position: relative;
  display: flex;
  align-items: flex-end;
  transition: 0.4s ease-out;
  box-shadow: 0px 7px 20px rgba(43, 8, 37, 0.2);
  border-radius: 20%;
}

.cardelwa:before {
  content: "";
  border-radius: 20%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(rgba(26, 34, 25, 0.771), rgba(20, 23, 20, 0.755)), url("../assets/Gallery/mecha.jpg");
  background-repeat: no-repeat;
  opacity: 0;
  background-size: cover;
  align-items: center;
  z-index: 2;
  transition: 0.5s;
}

.cardelga {
  width: 250px;
  height: 304px;
  border-radius: 1em;
  padding: 1.9rem;
  background: #f5f5f5;
  position: relative;
  display: flex;
  align-items: flex-end;
  transition: 0.4s ease-out;
  box-shadow: 0px 7px 20px rgba(43, 8, 37, 0.2);
  border-radius: 20%;
}

.cardelga:before {
  content: "";
  border-radius: 20%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(rgba(26, 34, 25, 0.771), rgba(20, 23, 20, 0.755)), url("../assets/Gallery/it.jpg");
  background-repeat: no-repeat;
  opacity: 0;
  background-size: cover;
  align-items: center;
  z-index: 2;
  transition: 0.5s;
}

.cardelpat {
  width: 250px;
  height: 304px;
  border-radius: 1em;
  padding: 1.9rem;
  background: #f5f5f5;
  position: relative;
  display: flex;
  align-items: flex-end;
  transition: 0.4s ease-out;
  box-shadow: 0px 7px 20px rgba(43, 8, 37, 0.2);
  border-radius: 20%;
}

.cardelpat:before {
  content: "";
  border-radius: 20%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(rgba(26, 34, 25, 0.771), rgba(20, 23, 20, 0.755)), url("../assets/Gallery/trade.jpg");
  background-repeat: no-repeat;
  opacity: 0;
  background-size: cover;
  align-items: center;
  z-index: 2;
  transition: 0.5s;
}

.cardel-info {
  position: relative;
  z-index: 3;
  color: #f5f5f5;
  opacity: 0;
  transform: translateY(20%);
  transition: 0.5s;
}

.text-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.cardel-info .card-text {
  color: #f5f5f5 !important;
}

.cardel-img {
  border-radius: 20%;
  background: #efead8;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
}

.cardel-img .imgel {
  position: absolute;
  width: 100px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: #5f7161;
}

.cardel:hover {
  transform: translateY(5%);
  opacity: 60%;
}

.cardel:hover:before {
  opacity: 1;
}

.cardel:hover .cardel-info {
  opacity: 1;
  transform: translateY(80px);
}

.cardelwa:hover {
  transform: translateY(5%);
  opacity: 60%;
}

.cardelwa:hover:before {
  opacity: 1;
}

.cardelwa:hover .cardel-info {
  opacity: 1;
  transform: translateY(80px);
}

.cardelga:hover {
  transform: translateY(5%);
  opacity: 60%;
}

.cardelga:hover:before {
  opacity: 1;
}

.cardelga:hover .cardel-info {
  opacity: 1;
  transform: translateY(80px);
}

/* --- */

.cardelpat:hover {
  transform: translateY(5%);
  opacity: 60%;
}

.cardelpat:hover:before {
  opacity: 1;
}

.cardelpat:hover .cardel-info {
  opacity: 1;
  transform: translateY(80px);
}

.navbar .nav-link {
  position: relative;
  left: 1%;
}

.navbar .nav-link::after {
  content: "";
  opacity: 0;
  transition: all 0.2s;
  height: 2px;
  width: 100%;
  background-color: #f7f6f6;
  position: absolute;
  bottom: 0;
  left: 0;
}

.navbar .nav-link:hover::after {
  opacity: 1;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #d02c2c79;
  color: #f3f3f3fa;
  font-weight: bold;
}

.logo-wrapper {
  width: 70px;
  height: 30px;
}

.logo {
  width: 100%;
  height: 100%;
}

.wuutt {
  margin-top: 10%;
}

.Hompeg {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 90vh;
  background-color: #1d0b0bd6;
  display: flex;
}

.hasatuhompeg {
  font-weight: bold;
}

.empid {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  display: block;
  width: 100%;
  height: 100%;
}

.contvid {
  position: absolute;
  display: block;
  width: 100%;
  height: 90%;
  top: 4%;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  color: white;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.contvid:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(37, 4, 4, 0.631);
}

.kontenneh {
  margin-top: 15%;
}

.kontennehisi {
  margin-top: 18.5%;
}

.operlay {
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100.5%;
  background-color: #1601013c;
}

.buttone {
  background-color: transparent;
  position: absolute;
  border: none;
  width: 10%;
  height: 10%;
  margin-top: 10%;
  margin-right: 15%;
  margin-left: 90%;
}

.buttone::after {
  content: "Play/Stop";
  position: absolute;
  top: 30%;
  left: 10%;
  transform: translateX(20%);
  transform: translateY(100%);

  background-color: rgb(168, 7, 7);
  padding: 4px 8px;
  border-radius: 5px;
  transition: 0.2s linear;
  transition-delay: 0.2s;
  color: white;
  text-transform: uppercase;
  font-size: 12px;
  opacity: 0;
  visibility: hidden;
}

.icon {
  transform: scale(1.1);
  transition: 0.2s linear;
}

.buttone:hover > .icon {
  transform: scale(1.1);
}

.buttone:hover > .icon path {
  fill: rgb(168, 7, 7);
}

.buttone:hover::after {
  visibility: visible;
  opacity: 1;
  top: -150%;
}

.parallax {
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.video-container {
  height: 200%;
  position: absolute;
  top: -50%;
  left: 0;
  width: 100%;
  z-index: -1;
}

.video-container video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.text-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
  z-index: 1;
}

.Ourgallery {
  margin-top: 8%;
}

.slidercol {
  flex: 0 0 auto;
  width: 50%;
  max-width: 50%;
}

.galeries {
  margin: 10%;
}

.gallery img {
  height: 500px;
}

.sliderour {
  border-radius: 80%;
  padding: 0px 10px;
}

.sliderour img {
  width: 100%;
  height: 100%;
  margin-bottom: -60%;
}

.ingponyaour {
  text-align: left;
  margin-left: 10%;
}

.haduagaleri {
  font-weight: bold;
}

.allitem {
  margin: 1%;
  width: 40%;
  height: 10%;
  border: 2px solid;
}

.menu-items {
  display: grid;
  grid-template-columns: repeat(1, 20fr);

  grid-gap: 100%;
}

.hasilkerja {
  width: 100%;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;

  transition: all 0.3s ease-in-out;
  margin-bottom: 5%;
  margin-top: 5%;
  padding: 1.9rem;
  position: relative;
  display: flex;
  align-items: flex-end;
}

.slidebanyak {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slidebanyak img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.thumbnail {
  object-fit: cover;
  margin-left: 4%;
  margin-right: 4%;
}

.hasilkerja:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;

  z-index: 2;
  transition: 0.5s;
}

.ingpomase {
  position: relative;
  z-index: 3;
  color: #f5f5f5;
  opacity: 0;
  transform: translateY(20%);
  transition: 0.5s;
}

.judule {
  font-size: 1.5rem;
  font-weight: bold;
  color: #080808;
}

.isine {
  letter-spacing: 1px;
  font-size: 0.9rem;
  margin: 5px 0 15px 0;
  color: #080808;
}

.gambarne {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}

.nyobaduls {
  margin: 0.5%;
}

.hasilkerja:hover:before {
  opacity: 1;
}

.hasilkerja:hover .ingpomase {
  opacity: 1;
  transform: translateY(0);
}

.hasilkerja:hover {
  transform: scale(1.05);
  z-index: 1;
}

.hasilkerja:not(:hover) {
  transform: scale(1);
}

.hasilkerja:hover .gambarne {
  opacity: 0.5;
  transition: opacity 0.5s ease-in-out;
}

.baten {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: #852525;

  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  border: none;
  color: #fff;
}

.aktip {
  background-color: #4562f1;
}

.baten:after {
  content: " ";
  width: 0%;
  height: 100%;
  background: #030301;
  position: absolute;
  transition: all 0.4s ease-in-out;
  right: 0;
}

.baten:hover::after {
  right: auto;
  left: 0;
  width: 100%;
  color: white;
}

.baten span {
  text-align: center;
  text-decoration: none;
  width: 100%;
  padding: 5px 15px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.03em;
  z-index: 20;
  transition: all 0.3s ease-in-out;
}

.baten:hover span {
  color: #183153;
  animation: scaleUp 0.3s ease-in-out;
  color: white;
}

@keyframes scaleUp {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(1);
  }
}

.faq {
  padding-top: 100px;
  padding-bottom: 150px;
}

.faq .accordion {
  --bs-accordion-bg: #f1faee;
}

.faq .accordion .accordion-item .accordion-header {
  line-height: 1;
}

.faq .accordion .accordion-button:focus {
  box-shadow: none;
}

.footer {
  background-image: linear-gradient(#131519f5, #29252c), url("../assets/imagePattern.jpg");
}

.footer .row:nth-child(2) {
  padding-top: 100px;
}

.jeda {
  position: absolute;
  margin-top: 50%;

  width: 100%;
  height: 30%;

  background: rgba(51, 66, 91, 0.88);
  box-shadow: 0px 24px 21px -1px rgba(0, 0, 0, 0.47);
  border-radius: 50px 50px 0px 0px;
}

.whatimg {
  width: 100%;
  height: 100%;
  border-radius: 10%;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}

.haduanyawhat {
  font-weight: bold;
}

.kelompoknyawhatimg {
  margin-top: 5%;
}


.parnership img {

  margin-top: -10vh;
  aspect-ratio: 3/2;
  object-fit: contain;
  scale: 50%;
}

.header-parallax {
  background-image: url('../assets/Gallery/201608092142155_b.jpg');
  /*GantidenganURLgambarlatarbelakangAnda*/background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  /*WarnatekssesuaikebutuhanAnda*/text-align: center;
  filter: brightness(70%);
  -webkit-filter: brightness(70%);
}

.Slaide {
  padding: 5vw;
}

.Slaide h1 {
  margin: 0 0 50px 0;
}

.cardeltu {
  padding: auto;
  margin: 1vh;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(102, 102, 102, 0.1);
  text-align: start;
  display: flex;
  flex-direction: column;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.cardeltu .card-img-top {
  max-width: 100%;
  height: 200px; /* Set the desired height for the images */
  object-fit: cover; /* Ensure the image covers the designated height */
}

.projek {

  margin: 10vh 0 50vh 0;
  padding: 0 40vh 0 40vh;

}

.projek h1 {
  margin-bottom: 10vh;
}


.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: white;
  animation: colorSlide 15s cubic-bezier(0.075, 0.82, 0.165, 1) infinite;

}

@keyframes colorSlide {
  0% { background-color: #152a68; }
  25% { background-color: royalblue; }
  50% { background-color: seagreen; }
  75% { background-color: tomato; }
  100% { background-color: #152a68; }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  };
  100% {
    opacity: 1;
  }
}



@media screen and (max-width: 750px) {
  .navbareltu .right {
    height: 0;
    overflow: hidden;
  }

  .toggle {
    position: absolute;
    top: 25px;
    right: 20px;
    z-index: 99;
    transform: translateY(-50%);
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .toggle > * {
    width: 50%;
    height: 2px;
    background: #fff;
    margin: 3px 0;
  }

  .navbareltu.collapsed .toggle .line1 {
    transform: rotate(-45deg) translate(-4px, 5px);
  }
  .navbareltu.collapsed .toggle .line2 {
    opacity: 0;
  }
  .navbareltu.collapsed .toggle .line3 {
    transform: rotate(45deg) translate(-5px, -6px);
  }

  .logotu {
    position: absolute;
    z-index: 45;
    width: 13%;
    margin-top: 3%;
    margin-left: 10%;
    height: 5%;
  }

  .navbareltu .links {
    position: fixed;
    background: #080808;
    z-index: 40;
    top: 0;
    left: -100%;
    flex-direction: column;
    width: 45vw;
    height: 100vh;
  }
  .navbareltu .links li:first-child {
    margin-top: 80px;
  }

  .navbareltu.collapsed .links {
    left: 0;
  }
  .navbareltu.collapsed .links a {
    color: #fff;
  }
  .navbareltu.collapsed .links li {
    width: 100%;
  }
  .navbareltu.collapsed .links li:hover a {
    color: #080808;
  }

  .navbareltu.collapsed .right {
    position: fixed;
    width: 45vw;
    text-align: center;
    height: auto;
    bottom: 40px;
    left: 22.5vw;
    transform: translateX(-50%);
    z-index: 45;
  }

  .navbareltu.collapsed ~ main {
    filter: blur(1px);
    opacity: 0.8;
  }

  .navbareltu.sticky {
    position: fixed;
    z-index: 40;
  }

  .containerel {
    margin-top: 20%;
  }

  .kumpulkartu {
    margin: 15%;
    margin-top: 0;
    padding: 10%;
  }

  .empid {
    height: 105%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
}

@media screen and (max-width: 1366px) {
  .empid {
    height: 110%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .contvid {
    width: 100%;
    height: 105%;
    top: 4%;
  }

  .buttone {
    margin-top: 40%;
  }
}

@media screen and (max-width: 998px) {
  .services {
    padding-top: 100px;
  }

  .projek {

    padding: 0 0 0 0;
  
  }

  .empid {
    height: 130%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .contvid {
    width: 100%;
    height: 120%;
    top: 4%;
  }

  .operlay {
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
    width: 100%;
    height: 104%;
    background-color: #160101c4;
  }

  .buttone {
    background-color: transparent;
    position: absolute;
    border: none;
    width: 9%;
    height: 10%;
    margin-top: 30%;
    margin-right: 0%;
    margin-left: 45%;
  }
}

@media screen and (min-width: 1920px), screen and (max-width: 1920px) {
  .kontenneh {
    margin-top: 15%;
  }

  .kontennehisi {
    margin-top: 18%;
  }

  .contvid {
    width: 100%;
    height: 100%;
    top: 4%;
  }

  .buttone {
    background-color: transparent;
    position: absolute;
    border: none;
    width: 10%;
    height: 10%;
    margin-top: 40%;
  }

  .buttone::after {
    content: "Play/Stop";
    position: absolute;
    left: 30%;
    transform: translateX(50%);
    transform: translateY(400%);
  }
}

@media screen and (max-width: 480px) {
  .kontenneh {
    margin-top: 75%;
  }

  .kontennehisi {
    margin-top: 80%;
  }

  .contvid {
    width: 100%;
    height: 100%;
    top: 4%;
  }

  .buttone {
    background-color: transparent;
    position: absolute;
    border: none;
    width: 10%;
    height: 10%;
    margin-top: 170%;
    margin-right: 10%;
    margin-left: 45%;
  }

  .buttone::after {
    content: "Play/Stop";
    position: absolute;
    top: 0%;
    left: -30%;
    transform: translateX(50%);
    transform: translateY(400%);
  }

  .kumpulkartu {
    margin: 0%;
    padding: 10%;
  }

  .cardel {
    width: 200px;
    height: 250px;
    border-radius: 1em;
    padding: 1.9rem;
    background: #f5f5f5;
    position: relative;
    display: flex;
    align-items: flex-end;
    transition: 0.4s ease-out;
    box-shadow: 0px 7px 20px rgba(43, 8, 37, 0.2);
    border-radius: 20%;
    margin-left: 20%;
  }

  .cardelwa {
    width: 200px;
    height: 250px;
    border-radius: 1em;
    padding: 1.9rem;
    background: #f5f5f5;
    position: relative;
    display: flex;
    align-items: flex-end;
    transition: 0.4s ease-out;
    box-shadow: 0px 7px 20px rgba(43, 8, 37, 0.2);
    border-radius: 20%;
    margin-left: 20%;
    margin-top: -15%;
  }

  .cardelga {
    width: 200px;
    height: 250px;
    border-radius: 1em;
    padding: 1.9rem;
    background: #f5f5f5;
    position: relative;
    display: flex;
    align-items: flex-end;
    transition: 0.4s ease-out;
    box-shadow: 0px 7px 20px rgba(43, 8, 37, 0.2);
    border-radius: 20%;
    margin-left: 20%;
    margin-top: -15%;
  }

  .cardelpat {
    width: 200px;
    height: 250px;
    border-radius: 1em;
    padding: 1.9rem;
    background: #f5f5f5;
    position: relative;
    display: flex;
    align-items: flex-end;
    transition: 0.4s ease-out;
    box-shadow: 0px 7px 20px rgba(43, 8, 37, 0.2);
    border-radius: 20%;
    margin-left: 20%;
    margin-top: -15%;
  }

  .cardel-info {
    position: relative;
    z-index: 3;
    color: #f5f5f5;
    opacity: 0;
    transform: translateY(50%);
    transition: 0.5s;
  }

  .text-title {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .cardel-info .card-text {
    color: #f5f5f5 !important;
  }

  .cardel-img {
    border-radius: 20%;
    background: #efead8;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
  }

  .cardel-img .imgel {
    position: absolute;
    width: 100px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    fill: #5f7161;
  }

  .cardel:hover {
    transform: translateY(5%);
    opacity: 60%;
  }

  .cardel:hover:before {
    opacity: 1;
  }

  .cardel:hover .cardel-info {
    opacity: 1;
    transform: translateY(80px);
  }

  .cardelwa:hover {
    transform: translateY(5%);
    opacity: 60%;
  }

  .cardelwa:hover:before {
    opacity: 1;
  }

  .cardelwa:hover .cardel-info {
    opacity: 1;
    transform: translateY(80px);
  }

  .cardelga:hover {
    transform: translateY(5%);
    opacity: 60%;
  }

  .cardelga:hover:before {
    opacity: 1;
  }

  .cardelga:hover .cardel-info {
    opacity: 1;
    transform: translateY(80px);
  }

  .slidercol {
    flex: 0 0 auto;
    width: 80%;
    max-width: 80%;
  }

  .baten span {
    text-align: center;
    text-decoration: none;
    width: 100%;
    padding: 5px 10px;
    color: #fff;
    font-size: 8px;
    font-weight: bold;
    letter-spacing: 0.03em;
    z-index: 20;
    transition: all 0.3s ease-in-out;
  }

  .ingponyaour {
    text-align: left;
    margin-left: 10%;
    margin-top: 10%;
  }
}
